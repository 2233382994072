import { Box, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import Logo from '../assets/images/logo.png';
import MrkLogo from '../assets/images/logo-mrk.png';

const LetterHeadInvoice = () => {
    return (
        <Box>
            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '10px'
                }}
            >
                <Box style={{ display: 'flex' }}>
                    <Box style={{ marginRight: '10px' }}>
                        <img src={Logo} alt="company name" width="80px" />
                    </Box>
                </Box>

                <Box textAlign="center">
                    <Typography variant="h3" color="primary.dark">
                        SURYA CENTRAL TREATMENT FACILITY
                    </Typography>
                    <Typography sx={{ mt: 1 }} variant="h4">
                        BIO MEDICAL WASTE DISPOSAL PLANT
                    </Typography>
                </Box>
                <Box textAlign="right">
                    <img src={MrkLogo} alt="company name" width="80px" />
                </Box>
            </Box>
            <Box>
                <Typography variant="h5" textAlign="center">
                    C/o Kore Lawns, Sahayog Nager Near Sphurti Chowk, Vishrambag, Sangli-416415 Tel.(0233)2305975 2302569
                </Typography>
            </Box>

            <Typography variant="h4" textAlign="center" sx={{ marginTop: 1 }}>
                GSTIN - 27AAAPE8628E1ZX
            </Typography>

            <Divider sx={{ borderBottomWidth: 1, mt: 1, mb: 2, bgcolor: '#000000' }} />
        </Box>
    );
};

export default LetterHeadInvoice;
