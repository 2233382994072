import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import CustomerProfileCollectionReport from 'views/customer-profile/Reports/CustomerProfileCollectionReport';
import MachineMaster from 'views/plant/machines/machine-master';
import MachineDetails from 'views/plant/machines/machine-master/machine-details';
import MachineMaintenanceLogs from 'views/plant/machines/maintenance-logs';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

const CustomerList = Loadable(lazy(() => import('views/customer-list')));
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const CustomerProfile = Loadable(lazy(() => import('views/customer-profile')));
const CustomerRegistrationForm = Loadable(lazy(() => import('views/customer-rgistration-form')));
const CustomerNewAccountForm = Loadable(lazy(() => import('views/customer-new-account')));
const CollectionRoutes = Loadable(lazy(() => import('views/collection-routes')));
const RouteSchedule = Loadable(lazy(() => import('views/route-schedule')));
const TodaysRoutes = Loadable(lazy(() => import('views/collection-routes/todays-routes')));
const Rescanning = Loadable(lazy(() => import('views/collection-routes/rescanning')));
const Tasks = Loadable(lazy(() => import('views/tasks')));
const Reports = Loadable(lazy(() => import('views/reports')));
const TaskDetails = Loadable(lazy(() => import('views/tasks/task-details')));

//Settings page routing
const AreaRates = Loadable(lazy(() => import('views/admin-pages/area-rates')));
const Drivers = Loadable(lazy(() => import('views/admin-pages/drivers')));
const Vehicles = Loadable(lazy(() => import('views/admin-pages/vehicles')));
const RouteScheduleTemplate = Loadable(lazy(() => import('views/admin-pages/route-schedule-template')));

//Bills and Receipts
const BillReceipts = Loadable(lazy(() => import('views/prints/index')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <CustomerList />
        },
        {
            path: '/sample-page',
            element: <SamplePage />
        },
        {
            path: '/dashboard',
            element: <DashboardDefault />
        },
        {
            path: '/customer-list',
            element: <CustomerList />
        },
        {
            path: '/customer-new-registration',
            element: <CustomerRegistrationForm />
        },
        {
            path: '/customer-new-account',
            element: <CustomerNewAccountForm />
        },
        {
            path: '/customer/customer-profile',
            element: <CustomerProfile />
        },
        {
            path: '/customer/collection-reports',
            element: <CustomerProfileCollectionReport />
        },
        {
            path: '/area-rates',
            element: <AreaRates />
        },
        {
            path: '/routes',
            element: <CollectionRoutes />
        },
        {
            path: '/route-schedule',
            element: <RouteSchedule />
        },
        {
            path: '/todays-routes',
            element: <TodaysRoutes />
        },
        {
            path: '/rescanning',
            element: <Rescanning />
        },
        {
            path: '/drivers',
            element: <Drivers />
        },
        {
            path: '/vehicles',
            element: <Vehicles />
        },
        {
            path: '/route-schedule-templates',
            element: <RouteScheduleTemplate />
        },
        {
            path: '/reports',
            element: <Reports />
        },
        {
            path: '/tasks',
            element: <Tasks />
        },
        {
            path: '/task-details',
            element: <TaskDetails />
        },
        {
            path: '/bill',
            element: <BillReceipts />
        },
        {
            path: '/plant/machines',
            element: <MachineMaster />
        },
        {
            path: '/plant/machine-details',
            element: <MachineDetails />
        },
        {
            path: '/plant/machine-maintenance-logs',
            element: <MachineMaintenanceLogs />
        }
    ]
};

export default MainRoutes;
