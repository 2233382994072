// assets
import {
    IconDashboard,
    IconBrandChrome,
    IconHelp,
    IconUsers,
    IconRoute,
    IconLayoutKanban,
    IconReport,
    IconSettings,
    IconSubtask,
    IconBuildingFactory
} from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconBrandChrome,
    IconHelp,
    IconUsers,
    IconRoute,
    IconLayoutKanban,
    IconReport,
    IconSettings,
    IconSubtask,
    IconBuildingFactory
};

// ==============================|| ADMIN MENU ITEMS ||============================== //

const adminMenuItems = [
    // {
    //     id: 'dashboard',
    //     type: 'group',
    //     children: [
    //         {
    //             id: 'default',
    //             title: 'Dashboard',
    //             type: 'item',
    //             url: '/dashboard',
    //             icon: icons.IconDashboard,
    //             breadcrumbs: false
    //         }
    //     ]
    // },
    {
        id: 'main-pages',
        type: 'group',
        children: [
            {
                id: 'customers',
                title: 'Customers',
                type: 'collapse',
                icon: icons.IconUsers,
                breadcrumbs: false,
                children: [
                    {
                        id: 'customer-list',
                        title: 'List',
                        type: 'item',
                        url: '/customer-list',
                        breadcrumbs: false
                    },
                    {
                        id: 'new-registration',
                        title: 'Register New Customer',
                        type: 'item',
                        url: '/customer-new-account',
                        breadcrumbs: false
                    }
                ]
            },
            {
                id: 'routes',
                title: 'Routes & Collection',
                type: 'collapse',
                icon: icons.IconRoute,
                breadcrumbs: false,
                children: [
                    {
                        id: 'routes',
                        title: 'Manage Routes',
                        type: 'item',
                        url: '/routes',
                        breadcrumbs: false
                    },
                    {
                        id: 'route-schedule',
                        title: 'Route Schedule',
                        type: 'item',
                        url: '/route-schedule',
                        breadcrumbs: false
                    },
                    {
                        id: 'todays-routes',
                        title: 'Todays Routes',
                        type: 'item',
                        url: '/todays-routes',
                        breadcrumbs: false
                    },
                    {
                        id: 'rescanning',
                        title: 'Rescanning',
                        type: 'item',
                        url: '/rescanning',
                        breadcrumbs: false
                    }
                ]
            },
            {
                id: 'tasks',
                title: 'Tasks',
                type: 'item',
                url: '/tasks',
                icon: icons.IconSubtask,
                breadcrumbs: false
            }
        ]
    },
    {
        id: 'plant-pages',
        type: 'group',
        children: [
            {
                id: 'plant',
                title: 'Plant',
                type: 'collapse',
                icon: icons.IconBuildingFactory,
                breadcrumbs: false,
                children: [
                    {
                        id: 'mahcine-list',
                        title: 'Machines',
                        type: 'item',
                        url: '/plant/machines',
                        breadcrumbs: false
                    },
                    {
                        id: 'drivers',
                        title: 'Maintenance Logs',
                        type: 'item',
                        url: '/plant/machine-maintenance-logs',
                        breadcrumbs: false
                    }
                ]
            },
            {
                id: 'other',
                title: 'Dummy',
                type: 'item',
                url: '/reports',
                icon: icons.IconReport,
                breadcrumbs: false
            }
        ]
    },
    {
        id: 'setting-pages',
        type: 'group',
        children: [
            {
                id: 'admin',
                title: 'Settings',
                type: 'collapse',
                icon: icons.IconSettings,
                breadcrumbs: false,
                children: [
                    {
                        id: 'rate-chart',
                        title: 'Areas & Rates',
                        type: 'item',
                        url: '/area-rates',
                        breadcrumbs: false
                    },
                    {
                        id: 'drivers',
                        title: 'Drivers',
                        type: 'item',
                        url: '/drivers',
                        breadcrumbs: false
                    },
                    {
                        id: 'vehicles',
                        title: 'Vehicles',
                        type: 'item',
                        url: '/vehicles',
                        breadcrumbs: false
                    },
                    {
                        id: 'routeScheduleTemplates',
                        title: 'Route Schedule Templates',
                        type: 'item',
                        url: '/route-schedule-templates',
                        breadcrumbs: false
                    }
                ]
            },
            {
                id: 'reports',
                title: 'Reports',
                type: 'item',
                url: '/reports',
                icon: icons.IconReport,
                breadcrumbs: false
            }
        ]
    }
];

export default adminMenuItems;
